<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar class="grey lighten-5 toolbar-custom" elevation="0"
                     style="background-color: #f7f7f7 !important;
                     border-bottom: 2px solid #e8e8e8 !important;">
            <v-toolbar-title style="font-size: 1.3rem">
              Заповнення поштових індексів
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-2">
            <v-form ref="form" v-model="formValid">
              <v-row class="dense-wrapper">
                <v-col cols="12" class="pt-0">
                  <v-switch
                      class="mt-1"
                      v-model="all_flats"
                      hide-details
                      label="Усі абоненти організації"
                      color="secondary"/>
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect
                      label="Об’єднана громада" v-model="amalgamated_hromada_id"
                      filled select_type="amalgamated_hromada" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect
                      label="Населений пункт" v-model="city_id"
                      :parent_id="amalgamated_hromada_id" :use_parent_id="true"
                      filled select_type="city" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect
                      label="Район міста" v-model="city_area_id"
                      :parent_id="city_id" :use_parent_id="true"
                      filled select_type="city-area" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12" v-if="city_area_id">
                  <AddressElementSelect
                      label="Вулиця" v-model="streets" multiple
                      :parent_id="city_area_id" :use_parent_id="true"
                      filled select_type="street-with-city-area" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12" v-else>
                  <AddressElementSelect
                      label="Вулиця(-ці)" v-model="streets" multiple
                      :parent_id="city_id" :use_parent_id="true"
                      filled select_type="street" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect
                      label="Будинок(-ки)" v-model="buildings" multiple
                      :parent_id="streets" :use_parent_id="true"
                      filled select_type="building" :disabled="all_flats"
                  />
                </v-col>

                <v-col cols="12">
                  <v-select v-model="odd_or_even"
                            :items="odd_or_even_select"
                            label="Парні або непарні"
                            hide-details filled color="grey"
                            :class="odd_or_even ? '' : 'req-star'"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <Checker :value="checker_id" @autocompleteChange="onCheckerChange"/>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select v-model="building_type"
                            :items="buildingTypes_select"
                            label="Вид житла"
                            hide-details filled color="grey"
                  />
                </v-col>

                <v-col class="pt-6">
                  <span class="success--text" style="font-size: 1.1rem">Налаштування послуги</span>
                  <v-divider class="mt-1"></v-divider>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-text-field v-model="postal_index" label="Поштовий індекс" v-integer style="flex: 1; margin-right: 12px"
                                filled hide-details color="grey" :class="postal_index ? '' : 'req-star'"
                  />
                  <a href="https://index.ukrposhta.ua/find-post-index" target="_blank" style="flex: 0 0 160px">Поштові індекси України</a>
                </v-col>
                <v-col cols="12">
                  <v-btn depressed text block :loading="loading"
                         color="secondary darken-1" class="button-accept" :disabled="!documentReady()" @click.stop="crud_operation">
                    Виконати
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fillTablesAPI from '@/utils/axios/fill_tables'
import {ALERT_SHOW} from "@/store/actions/alert"
import {buildingTypes_select} from "@/utils/icons"
export default {
  name: "FillPostalIndex",
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Checker: () => import("@/components/autocomplite/Checker")
  },
  data() {
    return {
      formValid: false,
      all_flats: false,
      checker_id: null,
      amalgamated_hromada_id: null,
      city_id: null,
      city_area_id: null,
      streets: [],
      buildings: [],
      odd_or_even: 'all',
      postal_index: null,
      building_type: null,
      odd_or_even_select: [
        { text: 'Усі', value: 'all' },
        { text: 'Непарні', value: 'odd' },
        { text: 'Парні', value: 'even' },
      ],
      buildingTypes_select,
      loading: false
    }
  },
  methods: {
    onCheckerChange(payload) {
      this.checker_id = (payload || {}).value || null
    },
    documentReady() {
      if (!this.all_flats) {
        if (!this.streets.length && !this.city_id && !this.buildings.length) {
          return false
        }
      }
      return !(!this.odd_or_even || !this.postal_index);

    },
    crud_operation() {
      if (this.documentReady()) {
        this.loading = true

        const payload = {
          all_flats: this.all_flats,
          checker_id: this.checker_id,
          amalgamated_hromada_id: this.amalgamated_hromada_id,
          city_id: this.city_id,
          city_area_id: this.city_area_id,
          streets: this.streets,
          buildings: this.buildings,
          odd_or_even: this.odd_or_even,
          building_type: this.building_type,
          postal_index: this.postal_index
        }
        fillTablesAPI.fill_postal_index(payload)
          .then(response => response.data)
          .then(data => {
            this.$store.dispatch(ALERT_SHOW, { text: `Заповнено: ${data.count} поштових індексів`, color: 'success' })
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
            .finally(() => {
              this.loading = false
            })

      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>